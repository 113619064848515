
<template>
  <div>
    <section class="section is-main-section">
      <h1 class="title">Terms of use</h1>
      <tiles>
        <card-component
          title="Terms of service"
          icon="id-card-o"
          class="tile is-child"
        >
          <div class="content">
            <p>By using ASTERICS tool and in your capacity as a User, you acknowledge that you have read and accept the terms of service described below (hereafter the "ToS"). You are advised to read the ToS whenever you use the tool, since they may be amended at any time without prior notice.<br/>
            When connecting to ASTERICS:</p>
            <ul>
              <li><strong>General information</strong>: refer to the <router-link class="link" to="/legalnotice">legal notice</router-link>.</li>
              <li><strong>Intellectual property rights relating to the content</strong>: you are visiting the website of a public authority. Up to some exceptions listed below, you can reuse the general structure of the website, the content, the text and the images displayed on the website/application according to the terms of the <a target="_blank" href="https://www.etalab.gouv.fr/licence-ouverte-open-licence">Etalab 2.0 open licence</a>. ASTERICS characters (<a href="../assets/img/biologiste-edit.svg">here</a> and <a href="../assets/img/help/explore/bio-variables-at-glance.png">here</a> have been created by <a href="https://camilleferrari.com/" target="_blank">Camille Ferrari</a>. Please               acknowledge her work when using these images. INRAE, as the publisher of this website, owns the trademarks and logos on the site unless otherwise stipulated. Consequently, you must not reuse the said trademarks, logos, and images without prior written authorisation from INRAE. Other content may also be subject to intellectual property rights.</li>
              <li><strong>Access to public information</strong>: If you have any questions about the public information on this website/application (identification, use, etc.), please contact the person who is responsible for providing access to the documents and who is duly authorised to answer questions about the reuse of public information (PRADA): INRAE PRADA, 147, rue de l’Université, 75338 Paris cedex 07 - Phone: +33 (0)1 42 75 90 00 - Email: prada@inrae.fr</li>
              <li><strong>Inbound or outbound hypertext links</strong>: <em>Hyperlinks from the website/application to external sites</em>. The application may contain links to external sites (partner or third-party sites). INRAE may not be held liable for the content, use of the content or operation of such external websites. <em>Hyperlinks from external sites to the website/application</em>. If you wish to add hyperlinks to the content of the website/application, you must:
              <ul>
                <li>Ensure that users can identify the origin and author of the document;</li>
                <li>Not violate the interests of INRAE, the publisher of the website/application.</li>
              </ul></li>
              <li><strong>Limitation of liability</strong>:
              <ul>
                <li><strong>INRAE's liability</strong>: <em>Website availability</em>. Unless otherwise stipulated by law, INRAE makes no representations regarding the availability of the website and does not warrant that the information on the website will always be present. <em>IT security</em>. Unless otherwise stipulated by law, INRAE does not warrant that the website, its features and its content are free of security flaws caused by hacking, intrusions, bugs, viruses or malware that may affect your computer hardware, software or data. You use the website, its features and its content at your own risk and under your sole responsibility. You are required to take all necessary preventive measures to protect your own data, software and/or computer systems against contamination from any viruses and attacks.</li>
                <li><strong>User's liability</strong>: <em>Content quality</em>. Despite its best efforts to disseminate reliable content, INRAE does not warrant that such content is free of errors, inaccuracies or omissions. You are solely responsible for viewing, choosing, using and interpreting the content on this website. INRAE may not be held, by any express or implied obligation, as liable to you or third parties for any direct or indirect damage arising from the use of the information, particularly by reason of inaccurate or incomplete information, or an indexing error with all or part of the website. <em>User accounts</em>. You are solely responsible for the use of your account and your unique project identifier, and you shall therefore hold INRAE harmless if your account is used fraudulently by a third party.</li>
              </ul></li>
              <li><strong>Accessibility</strong>: The site complies with accessibility standards. As such, each site features an accessibility statement and warrants its compliance with applicable accessibility rules. The long-term accessibility policy and the dedicated annual action plan are available at the following address: <a href="https://intranet.inrae.fr/systemes-information" target="_blank">https://intranet.inrae.fr/systemes-information</a>. <em>Reporting</em>. If you find an online communication service to be in breach of these terms of service, you can report the breach to INRAE at the following address: dsi@inrae.fr.</li>
              <li>This website collects <strong>personal data</strong>. By using ASTERICS, you acknowledge that you have read and accept the <router-link class="link" target="_blank" to="/privacy-policy">privacy policy</router-link>.</li>
              <li><strong>Amendments</strong>: The website editor reserves the right to amend these terms of service without prior notice.</li>
              <li><strong>Termination</strong>: You may terminate your use of the website at any time by sending an email to the email address indicated in the <router-link class="link" to="/legalnotice">legal notice</router-link> relating to this website. INRAE will promptly take your termination request into account. If you fail to comply with the obligations set forth herein, INRAE reserves the right to delete your account, to prevent you from re-registering, to take any action and/or remedies to stop any breach of these rights, and to obtain compensation for the damaged suffered.</li>
              <li><strong>Governing law - language - disputes</strong>These ToS shall be governed by and construed in accordance with French law, irrespective of the location in which the website is used. If any translated version of these ToS conflicts with the current version, only the current version will control. In the event of a dispute, you agree to contact INRAE in the first instance with the aim of reaching an amicable solution for any such disputes between the parties. If the parties are unable to reach an amicable solution in respect of a dispute arising from or in connection with the validity, interpretation and/or performance of these ToS, the French courts shall have sole jurisdiction and venue unless otherwise stipulated by legislation.</li>
              <li>Any publication using results of analyses performed with ASTERICS will have to cite the tool, as described below.</li>
              <li>Commercial use of ASTERICS is forbidden. ASTERICS is released under GNU General Public Licence version 3 (<a href="http://www.gnu.org/licenses/gpl-3.0.html" target="_blank">GPL 3</a>).</li>
            </ul>
          </div>
        </card-component>
      </tiles>
      <tiles>
        <card-component
          title="Citation"
          icon="book"
          class="tile is-child"
        ><b><a href="https://asterics.pages.mia.inra.fr/user_documentation/how-to-cite-asterics.html" target="_blank">Citation information.</a></b>
        </card-component>
      </tiles>
      <partners></partners>
    </section>
  </div>
</template>

<script>
import Tiles from '@/components/ui/Tiles'
import CardComponent from '@/components/ui/CardComponent'
import Partners from '@/components/ui/Partners.vue'

export default {
  name: 'About',
  components: {
    CardComponent,
    Tiles,
    Partners
  }
}
</script>
