
<template>
<div>
  <tiles>
    <card-component
          title="Partners "
          icon="handshake-o"
          class="tile is-child"
        >
        <div class="level">
            <div class="level-item">
              <div class="logo">
                <a target="_blank" href="https://www.math.univ-toulouse.fr/">
                  <img class="partner" src="../../assets/img/logo_IMT.jpeg" />
                </a>
              </div>
              <div class="logo">
                <a target="_blank" href="https://www.hyphen-stat.com/en/">
                  <img class="partner" src="../../assets/img/logo_hyphen.png" />
                </a>
              </div>
            <div class="logo">
                <a target="_blank" href="https://genphyse.toulouse.inra.fr/">
                  <img class="partner" src="../../assets/img/logo_GenPhySE.png" />
                </a>
              </div>
              <div class="logo">
                <a target="_blank" href="https://miat.inrae.fr">
                  <img class="partner" src="../../assets/img/logo_MIAT.png" />
                </a>
              </div>
              <div class="logo">
                <a target="_blank" href="https://inrae.fr">
                  <img class="partner" src="../../assets/img/logo_INRAE.png" />
                </a>
              </div>
            </div>
          </div>
        </card-component>
    </tiles>
    <tiles>
        <card-component
          title="Project coordinators"
          icon="bank"
          class="tile is-child"
        >
        <div class="level">
          <div class="level-item">
            <div class="logo">
              <a target="_blank" href="http://bioinfo.genotoul.fr/">
                <img style="height:50px; padding-right:40px;" src="../../assets/img/logo_bioinfo.png" />
              </a>
            </div>
            <div class="logo">
              <a target="_blank" href="https://www.genotoul.fr/portfolio_page/biostatistiques/">
                <img style="height:50px" src="../../assets/img/logo_biostat.jpg" />
              </a>
            </div>
          </div>
        </div>
      </card-component>
         <card-component
          title="Funder"
          icon="bank"
          class="tile is-child"
        >
        <div class="level">
            <div class="level-item">
            <div class="logo">
              <a target="_blank" href="https://www.laregion.fr/">
                <img style="max-height:50px" src="../../assets/img/logo_RegionOccitanie.jpg" />
              </a>
            </div>
          </div>
        </div>
      </card-component>
  </tiles>
</div>
</template>

<script>
import CardComponent from '@/components/ui/CardComponent'
import Tiles from '@/components/ui/Tiles'

export default {
  name: 'Partners',
  components: {
    CardComponent,
    Tiles
  }
}
</script>
<style>
.partner {
  padding:10px;
}
</style>
